
import * as ipxRuntime$FP9TjparIC from '/var/www/html/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "thumbnail": {
      "modifiers": {
        "format": "webp",
        "width": 450,
        "height": 254
      }
    },
    "client": {
      "modifiers": {
        "format": "webp",
        "width": 204,
        "height": 204
      }
    },
    "heroDetail": {
      "modifiers": {
        "format": "webp",
        "width": 960,
        "height": 540
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "suitmedia.static-assets.id"
  ],
  "alias": {
    "/suitmedia": "https://suitmedia.static-assets.id",
    "/youtube": "https://img.youtube.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$FP9TjparIC, defaults: {"maxAge":60000} }
}
        