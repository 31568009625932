<template>
  <div
    class="flex flex-wrap flex-col md:flex-row md:items-center text-sm md:text-base"
  >
    <p
      class="text-bw-700 font-medium md:mr-6 text-center md:text-left md:mb-1 lg:mb-0"
    >
      Engage with Us
    </p>
    <div
      class="flex flex-wrap gap-1.5 icons mt-3 md:mt-0 justify-center md:justify-normal"
    >
      <NuxtLink
        v-for="item in socialMedia.attributes.social_media"
        :key="item.id"
        :to="item.url"
        :title="item.type"
        target="_blank"
        rel="canonical"
        ><nuxt-icon :name="item.type" filled class="social-icons"
      /></NuxtLink>
    </div>
  </div>
</template>

<script setup>
const isLoading = ref(true)

const socialMedia = ref([])

const getSocialMedia = async () => {
  const { data, error, pending } = await useSuitmediaApiData(
    `/api/social-media`,
    {
      query: {
        'fields[0]': 'id',
        populate: 'social_media'
      }
    }
  )
  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Case Studies does not exist`
    })
  }

  return data.value
}

const socialMediaRes = await getSocialMedia()
socialMedia.value = socialMediaRes.data
</script>

<style lang="scss" scoped>
:deep(.social-icons) {
  svg {
    width: 16px;
    height: 16px;

    @media screen and (min-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
