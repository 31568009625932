<script setup>
// 404
// ARTICLE LIST
const isLoading = ref(true)
const contentsData = ref([])

const getContents = async () => {
  const { data, error, pending } = await useSuitmediaApiData(`/api/contents`, {
    query: {
      'pagination[pageSize]': 4,
      populate: 'image,layout_component,layout_component.type',
      'filters[$and][0][layout][$eq]': 'article',
      sort: 'publish_at:desc'
    }
  })
  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Articles does not exist`
    })
  }

  return data.value
}

// ARTICLE LIST
const contents = await getContents()
contentsData.value = contents.data

// ERROR HANDLING
const error = useError()

const handleError = () => {
  clearError({
    redirect: '/'
  })
}
// ERROR HANDLING
</script>

<template>
  <div>
    <Header />
    <div v-if="error">
      <template v-if="error.statusCode === 404">
        <div class="container mx-auto">
          <div class="mt-32 mb-14 flex justify-center items-center flex-col">
            <NuxtImg
              format="webp"
              class="w-4/5 sm:w-1/2 lg:w-1/4"
              src="/assets/img/404.png"
              alt="Error 404"
              title="Error 404"
            />
            <h1 class="text-4xl font-medium text-black-900 mt-14 text-center">
              Page Not Found
            </h1>
            <p
              class="mt-5 font-normal text-bw-800 leading-6 w-full sm:w-1/2 md:w-2/5 text-center"
            >
              Sorry, the page you are requested was not found. Please return to
              our homepage to find more information.
            </p>
            <button class="btn btn-primary btn-md mt-5" @click="handleError">
              Back to Homepage
            </button>
          </div>

          <!-- ARTICLES -->
          <div class="mb-15">
            <div class="mb-12">
              <h2
                class="mb-8 font-medium text-black-900 text-[40px] leading-[50px]"
              >
                Explore Our Expert Insights
              </h2>

              <div
                v-if="isLoading"
                class="rounded grid sm:grid-cols-none md:grid=cols-3 lg:grid-cols-4 place-items-center gap-11"
              >
                <div v-for="index in 4" :key="index" class="w-full">
                  <UtilsSkeletonLoading class="rounded-lg h-40 w-full mb-4" />
                  <UtilsSkeletonLoading class="w-full h-12 mb-3" />
                  <UtilsSkeletonLoading class="w-full h-12" />
                </div>
              </div>

              <div
                v-else
                class="grid gap-11 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2"
              >
                <ArticleCard
                  v-for="content in contentsData"
                  :key="content.id"
                  :with-logo="false"
                  :title="content?.attributes?.title"
                  :desc="
                    content?.attributes?.layout_component?.[0]?.description
                  "
                  :image-url="content?.attributes?.image?.data?.attributes?.url"
                  :slug="`/ideas/${content?.attributes?.slug}`"
                />
              </div>
            </div>

            <div class="flex justify-center items-center w-full">
              <CommonViewAllButton url="/ideas" page-name="Articles" />
            </div>
          </div>
        </div>
        <!-- ARTICLES -->

        <Footer />
      </template>
      <div v-else class="flex justify-center h-screen items-center flex-col">
        <NuxtImg
          format="webp"
          class="w-1/4"
          src="/assets/img/500.png"
          alt="Error 500"
          title="Error 500"
        />
        <h1 class="text-4xl font-medium text-black-900 mt-5">
          Internal Server Error
        </h1>
        <p class="mt-5 font-normal text-bw-800 leading-6 w-5/12 text-center">
          Oops, we were unable to complete your request since the server
          encountered an internal error or misconfiguration. Try to refresh this
          page later.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
