export default defineNuxtRouteMiddleware(to => {
  const config = useRuntimeConfig()

  if (config.public.IS_MAINTENANCE === '1' && to.path !== '/maintenance') {
    return navigateTo('/maintenance')
  } else if (
    config.public.IS_MAINTENANCE === '0' &&
    to.path === '/maintenance'
  ) {
    return navigateTo('/')
  }
})
