import { default as _91static_93AwXM1Fs4MtMeta } from "/var/www/html/pages/[static].vue?macro=true";
import { default as approach4kIIzoJBmXMeta } from "/var/www/html/pages/about/approach.vue?macro=true";
import { default as historycwko8AmmLxMeta } from "/var/www/html/pages/about/history.vue?macro=true";
import { default as indextu5vNqDNuuMeta } from "/var/www/html/pages/about/index.vue?macro=true";
import { default as purposef4xFvtd32UMeta } from "/var/www/html/pages/about/purpose.vue?macro=true";
import { default as teamDBhBjgcfEBMeta } from "/var/www/html/pages/about/team.vue?macro=true";
import { default as _91slug_93dPJ3nxiEtCMeta } from "/var/www/html/pages/careers/[slug].vue?macro=true";
import { default as cultureUJVkBxe5WbMeta } from "/var/www/html/pages/careers/culture.vue?macro=true";
import { default as indexwoFQ5uJnz5Meta } from "/var/www/html/pages/careers/index.vue?macro=true";
import { default as contactZjsR3yTbTmMeta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as _91slug_93FbgXc6T368Meta } from "/var/www/html/pages/expertises/[slug].vue?macro=true";
import { default as indexiDR63hclhOMeta } from "/var/www/html/pages/expertises/index.vue?macro=true";
import { default as _91slug_93ytevEb072xMeta } from "/var/www/html/pages/ideas/[slug].vue?macro=true";
import { default as index49gNhfUTT5Meta } from "/var/www/html/pages/ideas/index.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as _91slug_93By27GN49FIMeta } from "/var/www/html/pages/industries/[slug].vue?macro=true";
import { default as indexsflqHqNdrgMeta } from "/var/www/html/pages/industries/index.vue?macro=true";
import { default as indexDMk6BCwKOeMeta } from "/var/www/html/pages/insights/index.vue?macro=true";
import { default as maintenanceFfl67w51TlMeta } from "/var/www/html/pages/maintenance.vue?macro=true";
import { default as successiWWc1sCNv0Meta } from "/var/www/html/pages/newsletter/success.vue?macro=true";
import { default as indexTi2v2AFSZRMeta } from "/var/www/html/pages/podcasts/index.vue?macro=true";
import { default as _91slug_93UanbeaRGiNMeta } from "/var/www/html/pages/products/[slug].vue?macro=true";
import { default as indexikhioupTWxMeta } from "/var/www/html/pages/products/index.vue?macro=true";
import { default as searchhcB92YYWLxMeta } from "/var/www/html/pages/search.vue?macro=true";
import { default as _91slug_93YqYc2GbMWsMeta } from "/var/www/html/pages/solutions/[slug].vue?macro=true";
import { default as index674m5y27XBMeta } from "/var/www/html/pages/solutions/index.vue?macro=true";
import { default as _91slug_93Myr740DVYXMeta } from "/var/www/html/pages/sustainability/[slug].vue?macro=true";
import { default as indexK4leLMw8LyMeta } from "/var/www/html/pages/sustainability/index.vue?macro=true";
import { default as _91slug_93ZWlUqb78RhMeta } from "/var/www/html/pages/videos/[slug].vue?macro=true";
import { default as indextz3zSuR1dAMeta } from "/var/www/html/pages/videos/index.vue?macro=true";
import { default as _91slug_93dEhsaIasxaMeta } from "/var/www/html/pages/work/[slug].vue?macro=true";
import { default as indexfIQz7HXQ0EMeta } from "/var/www/html/pages/work/index.vue?macro=true";
export default [
  {
    name: "static",
    path: "/:static()",
    component: () => import("/var/www/html/pages/[static].vue")
  },
  {
    name: "about-approach",
    path: "/about/approach",
    component: () => import("/var/www/html/pages/about/approach.vue")
  },
  {
    name: "about-history",
    path: "/about/history",
    component: () => import("/var/www/html/pages/about/history.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/html/pages/about/index.vue")
  },
  {
    name: "about-purpose",
    path: "/about/purpose",
    component: () => import("/var/www/html/pages/about/purpose.vue")
  },
  {
    name: "about-team",
    path: "/about/team",
    component: () => import("/var/www/html/pages/about/team.vue")
  },
  {
    name: "careers-slug",
    path: "/careers/:slug()",
    component: () => import("/var/www/html/pages/careers/[slug].vue")
  },
  {
    name: "careers-culture",
    path: "/careers/culture",
    component: () => import("/var/www/html/pages/careers/culture.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/var/www/html/pages/careers/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/var/www/html/pages/contact.vue")
  },
  {
    name: "expertises-slug",
    path: "/expertises/:slug()",
    component: () => import("/var/www/html/pages/expertises/[slug].vue")
  },
  {
    name: "expertises",
    path: "/expertises",
    component: () => import("/var/www/html/pages/expertises/index.vue")
  },
  {
    name: "ideas-slug",
    path: "/ideas/:slug()",
    component: () => import("/var/www/html/pages/ideas/[slug].vue")
  },
  {
    name: "ideas",
    path: "/ideas",
    component: () => import("/var/www/html/pages/ideas/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/html/pages/index.vue")
  },
  {
    name: "industries-slug",
    path: "/industries/:slug()",
    component: () => import("/var/www/html/pages/industries/[slug].vue")
  },
  {
    name: "industries",
    path: "/industries",
    component: () => import("/var/www/html/pages/industries/index.vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/var/www/html/pages/insights/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceFfl67w51TlMeta || {},
    component: () => import("/var/www/html/pages/maintenance.vue")
  },
  {
    name: "newsletter-success",
    path: "/newsletter/success",
    component: () => import("/var/www/html/pages/newsletter/success.vue")
  },
  {
    name: "podcasts",
    path: "/podcasts",
    component: () => import("/var/www/html/pages/podcasts/index.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/var/www/html/pages/products/[slug].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/html/pages/products/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/html/pages/search.vue")
  },
  {
    name: "solutions-slug",
    path: "/solutions/:slug()",
    component: () => import("/var/www/html/pages/solutions/[slug].vue")
  },
  {
    name: "solutions",
    path: "/solutions",
    component: () => import("/var/www/html/pages/solutions/index.vue")
  },
  {
    name: "sustainability-slug",
    path: "/sustainability/:slug()",
    component: () => import("/var/www/html/pages/sustainability/[slug].vue")
  },
  {
    name: "sustainability",
    path: "/sustainability",
    component: () => import("/var/www/html/pages/sustainability/index.vue")
  },
  {
    name: "videos-slug",
    path: "/videos/:slug()",
    component: () => import("/var/www/html/pages/videos/[slug].vue")
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("/var/www/html/pages/videos/index.vue")
  },
  {
    name: "work-slug",
    path: "/work/:slug()",
    component: () => import("/var/www/html/pages/work/[slug].vue")
  },
  {
    name: "work",
    path: "/work",
    component: () => import("/var/www/html/pages/work/index.vue")
  }
]