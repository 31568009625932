<template>
  <div class="w-full md:block md:w-auto z-10 top-full hidden">
    <ul
      class="font-medium flex flex-col md:p-0 border border-gray-100 md:flex-row md:space-x-8 mt-0 md:border-0"
    >
      <li
        v-for="menu in menus"
        :key="menu.id"
        class="destkop-nav--list font-normal"
      >
        <NuxtLink
          v-if="menu.attributes.children.data.length === 0"
          :to="menu.attributes.url"
          :title="menu.attributes.title"
          :class="isTransparent && topOfPage && !showChild ? 'text-white' : ''"
          class="hover:text-[#FF6700] ease-in-out duration-200 transition-all py-4"
        >
          {{ menu.attributes.title }}
        </NuxtLink>

        <!-- Menu that has child -->
        <div
          v-else
          class="main-list"
          @mouseover="onHoverWorkMenu"
          @mouseleave="onLeaveWorkMenu"
        >
          <div
            class="parent-link cursor-pointer flex gap-x-2 ease-in-out duration-200 transition-all"
            :class="
              isTransparent && topOfPage && !showChild ? 'text-white' : ''
            "
          >
            <span class="self-center">{{ menu.attributes.title }}</span>
            <NuxtIcon
              name="chevron-down"
              filled
              class="self-center chevron-down-icon transition-transform duration-200"
              :class="isTransparent && topOfPage ? 'icon-light' : 'icon-dark'"
            />
          </div>
          <div
            v-show="showChild"
            class="pt-4 flex absolute left-0 right-0 children-list"
          >
            <div class="bg-white w-full">
              <div class="flex children-link w-full py-3 container mx-auto">
                <ul class="w-[30%] h-fit bg-[#F9F9F9] rounded-lg">
                  <li
                    v-for="(childmenu, i) in menu.attributes.children.data"
                    :key="i"
                    class="w-full px-4 hover:font-medium hover:text-primary-primary hover:bg-[#F2F2F2] children-title first:rounded-t-lg"
                    :class="
                      activeChildMenu === i
                        ? 'font-medium active-child text-primary-primary bg-[#F2F2F2]'
                        : 'font-normal text-bw-500'
                    "
                    @click="onClickChildMenu(childmenu, i)"
                  >
                    <NuxtLink
                      v-if="childmenu.attributes.title === 'Case Studies'"
                      :to="childmenu.attributes.url"
                      :title="childmenu.attributes.title"
                      class="flex items-center hover:font-medium cursor-pointer py-3 children-title--links text-lg"
                      :class="
                        activeChildMenu === i
                          ? 'text-primary-primary'
                          : '!text-bw-500'
                      "
                    >
                      <NuxtIcon
                        :name="childmenu.attributes.icon"
                        filled
                        class="mr-3 work-icon"
                        :class="activeChildMenu === i ? 'primary-icon' : ''"
                      />
                      {{ childmenu.attributes.title }}
                    </NuxtLink>
                    <div
                      v-else
                      class="flex items-center hover:font-medium cursor-pointer py-3"
                    >
                      <NuxtIcon
                        :name="childmenu.attributes.icon"
                        filled
                        class="mr-3 work-icon"
                        :class="activeChildMenu === i ? 'primary-icon' : ''"
                      />
                      {{ childmenu.attributes.title }}
                      <NuxtIcon
                        name="chevron-right"
                        filled
                        class="chevron-right-icon ml-auto"
                      />
                    </div>
                  </li>
                </ul>

                <!-- Child menu that has grandchild -->
                <div
                  class="grandchild-list px-3 lg:px-10 w-[70%] pb-5 min-h-[460px]"
                >
                  <div v-if="isLoading" class="w-full">
                    <div class="grid grid-cols-2 gap-4 mt-5">
                      <div v-for="i in 8" :key="i" class="flex flex-col gap-4">
                        <UtilsSkeletonLoading class="w-full h-9" />
                      </div>
                    </div>
                  </div>
                  <template v-else>
                    <!-- List of great grandchild -->
                    <div class="grandchild-link font-normal">
                      <Transition name="grandchild-transition">
                        <ul
                          v-if="childmenuData.attributes.title === 'Expertises'"
                          class="grid grid-cols-2"
                        >
                          <li
                            v-for="expertise in mainMenu.expertises"
                            :key="expertise.id"
                            class="py-2"
                          >
                            <p
                              class="font-normal text-sm text-[#B5B6B9] cursor-default px-4"
                            >
                              {{ expertise.title }}
                            </p>
                            <ul class="flex flex-col gap-2 mt-2">
                              <li
                                v-for="item in expertise.grandchild"
                                :key="item.id"
                                class="hover:text-[#FF6700] hover:bg-[#f2f2f2] text-sm cursor-pointer mr-6 last:mr-0 text-bw-700 py-2 px-4"
                              >
                                <NuxtLink
                                  :to="`/expertises/${item.attributes?.slug}`"
                                  :title="item.attributes?.title"
                                  class="block w-full"
                                  >{{ item.attributes?.title }}</NuxtLink
                                >
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <ul
                          v-else-if="
                            childmenuData.attributes.title === 'Industries'
                          "
                          class="grid grid-cols-2"
                        >
                          <li
                            v-for="grandchilmenu in childmenuData.grandchild"
                            :key="grandchilmenu.id"
                            class="hover:text-[#FF6700] hover:bg-[#f2f2f2] text-bw-900 cursor-pointer py-2 px-4"
                          >
                            <NuxtLink
                              :to="`${childmenuData.attributes.url}/${grandchilmenu.attributes.slug}`"
                              :title="grandchilmenu.attributes.title"
                              class="text-sm leading-[22px] block w-full"
                              >{{ grandchilmenu.attributes.title }}</NuxtLink
                            >
                          </li>
                        </ul>
                        <ul
                          v-else-if="
                            childmenuData.attributes.title === 'Solutions'
                          "
                          class="grid grid-cols-2"
                        >
                          <li
                            v-for="grandchilmenu in childmenuData.grandchild"
                            :key="grandchilmenu.id"
                            class="hover:text-[#FF6700] hover:bg-[#f2f2f2] text-bw-900 cursor-pointer py-2 px-4"
                          >
                            <NuxtLink
                              :to="`${childmenuData.attributes.url}/${grandchilmenu.attributes.slug}`"
                              :title="grandchilmenu.attributes.title"
                              class="text-sm leading-[22px] block w-full"
                              >{{ grandchilmenu.attributes.title }}</NuxtLink
                            >
                          </li>
                        </ul>
                        <ul
                          v-else-if="
                            childmenuData.attributes.title === 'Products'
                          "
                          class="grid grid-cols-2"
                        >
                          <li
                            v-for="grandchilmenu in childmenuData.grandchild"
                            :key="grandchilmenu.id"
                            class="hover:text-[#FF6700] hover:bg-[#f2f2f2] text-bw-900 cursor-pointer py-2 px-4"
                          >
                            <NuxtLink
                              :to="`${childmenuData.attributes.url}/${grandchilmenu.attributes.slug}`"
                              :title="grandchilmenu.attributes.title"
                              class="text-sm leading-[22px] block w-full"
                              >{{ grandchilmenu.attributes.title }}</NuxtLink
                            >
                          </li>
                        </ul>
                        <div
                          v-else-if="
                            childmenuData.attributes.title === 'Case Studies'
                          "
                          class="grid grid-cols-2"
                        ></div>
                      </Transition>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useMainMenuStore } from '~/store/mainMenu'

const props = defineProps({
  menus: {
    type: Array,
    default: () => []
  },
  isTransparent: {
    type: Boolean,
    default: false
  },
  topOfPage: {
    type: Boolean,
    default: true
  }
})

const route = useRoute()
const mainMenu = useMainMenuStore()

const emit = defineEmits(['hoveringWorkMenu'])

const activeChildMenu = ref(0)
const childmenuData = ref({})
const expertises = ref(mainMenu.expertises)
const defaultChildMenuData = ref({})

const isLoading = ref(true)
const showChild = ref(false)

const getWorkList = async path => {
  const query = {}

  if (path === 'expertices') {
    query['populate[expertice_category][fields][0]'] = 'title'
    query['populate[expertice_category][fields][1]'] = 'slug'
  }

  if (path === 'contents') {
    query['pagination[pageSize]'] = 2
    query.populate =
      'image,layout_component,layout_component.type,study_case_client.image'
    query['filters[$and][0][layout][$eq]'] = 'study-case'
    query['sort[0]'] = 'createdAt:desc'
  }

  if (path === 'industries' || path === 'products') {
    query['sort[0]'] = 'title:asc'
  }

  if (path === 'solutions') {
    query['sort[0]'] = 'order'
  }

  const { data, error, pending } = await useSuitmediaApiData(`/api/${path}`, {
    query
  })

  isLoading.value = pending.value
  childmenuData.value.grandchild = data.value.data

  if (path === 'expertices') {
    const newExpertises = expertises.value.map(expert => {
      return {
        id: expert.id,
        ...expert.attributes,
        grandchild: data.value.data.filter(
          item =>
            item.attributes.expertice_category?.data?.attributes?.title ===
            expert.attributes.title
        )
      }
    })

    mainMenu.changeExpertises(newExpertises)
  }

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: 'Data does not exist'
    })
  }
}

const workMenu = props.menus.find(menu => menu.attributes.title === 'Work')
childmenuData.value = workMenu.attributes.children.data[0]
defaultChildMenuData.value = workMenu.attributes.children.data[0]

await getWorkList(workMenu.attributes.children.data[0].attributes.api_path)

const onClickChildMenu = async (childmenu, i) => {
  activeChildMenu.value = i
  childmenuData.value = childmenu

  if (childmenu.grandchild || childmenu.attributes.title === 'Case Studies')
    return

  isLoading.value = true
  await getWorkList(childmenu.attributes.api_path)
}

const onHoverWorkMenu = () => {
  showChild.value = true
  emit('hoveringWorkMenu', true)
}
const onLeaveWorkMenu = () => {
  showChild.value = false
  emit('hoveringWorkMenu', false)
}

watch(
  () => route.path,
  () => {
    showChild.value = false
    // activeChildMenu.value = 0
    // childmenuData.value = defaultChildMenuData.value
  }
)
</script>

<style lang="scss" scoped>
.children-list {
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
}

:deep(.arr-right) {
  svg {
    width: 14px;
    height: 14px;
    fill: white;
    color: white;
  }
}

.main-list {
  :deep(.icon-light) {
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
  :deep(.icon-dark) {
    svg {
      path {
        fill: #4b4b4b !important;
      }
    }
  }

  &:hover {
    :deep(.chevron-down-icon) {
      transform: rotate(180deg);
      svg {
        path {
          fill: #ff6700 !important;
        }
      }
    }
    .parent-link {
      color: #ff6700;
    }
    .children-list {
      // display: flex;
      opacity: 0;
      animation: sectionFadein 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      animation-fill-mode: forwards;

      .children-title {
        &:hover {
          .children-title--links {
            color: #ff6700 !important;
          }
          :deep(.chevron-right-icon) {
            svg {
              path {
                fill: #ff6700 !important;
              }
            }
          }
          :deep(.work-icon) {
            svg {
              path {
                fill: #ff6700 !important;
              }
            }
          }
        }
      }

      .active-child {
        :deep(.chevron-right-icon) {
          svg {
            path {
              fill: #ff6700 !important;
            }
          }
        }
      }
    }
  }
}

@keyframes sectionFadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.card {
  &:hover {
    .card-title {
      color: #ff6700;
    }
  }
}

.card-overlay {
  background: linear-gradient(
    153deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  opacity: 0.4;
}

.filter-white {
  filter: brightness(0) invert(1);
}

:deep(.work-icon) {
  svg {
    width: 24px;
    height: 24px;
  }
}
:deep(.primary-icon) {
  svg {
    path {
      fill: #ff6700 !important;
    }
  }
}

:deep(.cs) {
  &:hover {
    .arrow-icon {
      display: block;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.line-dash {
  border: none;
  border-left: 1px solid #e8e8e8;
  width: 1px;
  height: 100%;
  margin: auto 8px;
  border-width: 2px;
  padding: 10px 0;
}

.grandchild-transition-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}
.grandchild-transition-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.grandchild-transition-enter-active {
  transition: 0.4s all ease;
}
</style>
