<template>
  <div class="h-full w-full relative">
    <nav class="flex items-center justify-between container mx-auto">
      <NuxtLink class="text-3xl font-mono py-3 md:py-0" to="/" title="Homepage">
        <NuxtImg
          format="webp"
          src="/assets/img/site-logo.png"
          alt="Suitmedia Digital Agency"
          title="Suitmedia Digital Agency"
          width="100"
          quality="100"
          class="ease-in-out duration-200 transition-all site-logo"
          :class="
            isTransparent &&
            topOfPage &&
            !openSearch &&
            !hoverWorkMenu &&
            !showMenu
              ? 'logos-invert'
              : ''
          "
        />
      </NuxtLink>

      <!-- Desktop Nav -->
      <HeaderDesktopNav
        v-show="!openSearch"
        id="navbar-default"
        :menus="mainMenu.menus"
        :is-transparent="isTransparent"
        :top-of-page="topOfPage"
        @hovering-work-menu="emitHoverWorkMenu"
      />

      <!-- Site Search and Contact Desktop -->
      <div class="h-full items-center flex" :class="openSearch ? 'ssc' : ''">
        <form
          v-if="openSearch"
          id="searchForm"
          class="search-input w-full hidden md:flex items-center border-b border-gray-600 relative"
          :class="
            isTransparent &&
            topOfPage &&
            !openSearch &&
            !hoverWorkMenu &&
            !showMenu
              ? 'site-search-white'
              : 'site-search'
          "
          autocomplete="off"
          @submit.prevent="onSiteSearch"
        >
          <button aria-label="search-button" type="submit" class="mr-4">
            <NuxtIcon
              name="search"
              filled
              :class="
                isTransparent &&
                topOfPage &&
                !openSearch &&
                !hoverWorkMenu &&
                !showMenu
                  ? 'search-icon-white'
                  : 'search-icon'
              "
            />
          </button>
          <input
            id="siteSearch"
            v-model="search"
            type="text"
            name="siteSearch"
            class="w-full focus-visible:outline-none"
            :class="
              isTransparent &&
              topOfPage &&
              !openSearch &&
              !hoverWorkMenu &&
              !showMenu
                ? 'bg-transparent text-white'
                : 'text-bw-800'
            "
            @keyup.prevent="onInputSearchSuggestion"
          />
          <button
            aria-label="close-search-button"
            class=""
            type="button"
            @click="toggleSearch"
          >
            <NuxtIcon name="cross" filled />
          </button>

          <div
            v-if="showSearchSuggestion"
            class="search-suggestion p-5 w-full absolute bg-white rounded-md top-[157%] left-0"
          >
            <ul class="flex flex-col gap-5">
              <template v-if="loadingSuggestion">
                <UtilsSkeletonLoading class="w-2/3 h-10" />
                <UtilsSkeletonLoading class="w-1/2 h-10" />
              </template>
              <template v-else>
                <li v-for="item in searchSuggestion" :key="item.id">
                  <NuxtLink
                    :to="`${itemUrl(item.attributes?.layout)}/${
                      item.attributes?.slug
                    }`"
                    :title="item.attributes?.title"
                    class="text-bw-800 hover:text-primary-primary"
                  >
                    {{ item.attributes?.title }}
                  </NuxtLink>
                </li>
              </template>
            </ul>
          </div>
        </form>

        <label
          for="siteSearch"
          aria-label="search-button"
          class="md:mr-4 hidden md:flex cursor-pointer"
          @click="toggleSearch()"
        >
          <NuxtIcon
            v-if="!openSearch"
            name="search"
            filled
            :class="
              isTransparent &&
              topOfPage &&
              !openSearch &&
              !hoverWorkMenu &&
              !showMenu
                ? 'search-icon-white'
                : 'search-icon'
            "
          />
        </label>

        <button
          id="buttonShowMenu"
          aria-label="mobile-nav-button"
          type="button"
          class="inline-flex items-center py-2 text-sm text-gray-500 md:hidden relative"
          aria-controls="navbar-default"
          aria-expanded="false"
          @click="emitToggleMobileMenu"
        >
          <Transition :name="transitionName">
            <NuxtIcon
              v-if="showMenu"
              name="cross-circle"
              filled
              class="cross-circle-icon absolute right-0"
            />

            <div v-else class="flex items-center">
              <span
                class="text-base mr-1"
                :class="
                  isTransparent && topOfPage ? 'text-white' : 'text-bw-900'
                "
                >Menu</span
              >
              <NuxtIcon name="three-lines" filled class="three-lines-icon" />
            </div>
          </Transition>
        </button>

        <NuxtLink
          to="/contact"
          title="Contact"
          class="px-4 py-2 btn btn-primary contact hidden md:flex"
        >
          Contact
        </NuxtLink>
      </div>
    </nav>
    <!-- Mobile Nav -->

    <Transition duration="200" name="slide-fadetop">
      <HeaderMobileNav
        v-if="showMenu"
        id="mobileNav"
        :menus="mainMenu.menus"
        @close-menu="showMenu = false"
      />
    </Transition>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useMainMenuStore } from '~/store/mainMenu'

defineProps({
  isTransparent: {
    type: Boolean,
    default: false
  },
  topOfPage: {
    type: Boolean,
    default: true
  }
})

await useFetchMenus()
await useFetchExpertisesCategories()

const mainMenu = useMainMenuStore()
const route = useRoute()
const router = useRouter()

const emit = defineEmits([
  'searchOpenned',
  'hoveringWorkMenu',
  'openMobileMenu'
])

const search = ref(null)
const showMenu = ref(false)
const openSearch = ref(false)
const hoverWorkMenu = ref(false)

const showSearchSuggestion = ref(false)
const loadingSuggestion = ref(false)
const searchSuggestion = ref([])
const transitionName = ref('spin')

const el = ref(null)

onMounted(() => {
  el.value = document.getElementById('navbar-default')

  window.addEventListener('click', onClickOutside)
  window.addEventListener('click', onClickFormSearch)
})

const onClickOutside = e => {
  if (
    e.target !== el.value &&
    e.target !== document.getElementById('searchForm')
  ) {
    // showMenu.value = false
    showSearchSuggestion.value = false
  }
}

const onClickFormSearch = e => {
  if (
    e.target === document.getElementById('siteSearch') &&
    searchSuggestion.value.length !== 0
  ) {
    showSearchSuggestion.value = true
  }
}

const getSuggestions = async () => {
  const query = {
    'pagination[page]': 1,
    'pagination[pageSize]': 5,
    'filters[title][$contains]': search.value,
    'sort[0]': 'publish_at:desc'
  }

  const { data, error, pending } = await useSuitmediaApiData(`/api/contents`, {
    query
  })

  loadingSuggestion.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: 'Contents does not exist'
    })
  }

  searchSuggestion.value = data.value.data

  if (searchSuggestion.value.length === 0) {
    showSearchSuggestion.value = false
  }
}

const toggleSearch = () => {
  openSearch.value = !openSearch.value
  emit('searchOpenned', openSearch.value)
}

const onSiteSearch = () => {
  router.push({ path: '/search', query: { keyword: search.value, page: 1 } })

  openSearch.value = false
}

const onInputSearchSuggestion = debounce(async val => {
  if (
    val.keyCode === 18 ||
    val.keyCode === 17 ||
    val.keyCode === 16 ||
    val.keyCode === 91
  ) {
    return
  }

  if (!search.value) {
    showSearchSuggestion.value = false
    searchSuggestion.value = []
    return
  }

  showSearchSuggestion.value = true
  loadingSuggestion.value = true
  await getSuggestions()
}, 500)

const itemUrl = layout => {
  if (layout === 'study-case') {
    return '/work'
  } else if (layout === 'video') {
    return '/videos'
  } else if (layout === 'podcast') {
    return ''
  } else if (layout === 'sustainability') {
    return '/sustainability'
  } else if (layout === 'article') {
    return '/ideas'
  }
}

const emitHoverWorkMenu = isHovering => {
  hoverWorkMenu.value = isHovering
  emit('hoveringWorkMenu', isHovering)
}

const emitToggleMobileMenu = () => {
  showMenu.value = !showMenu.value
  emit('openMobileMenu', showMenu.value)
}

watch(
  () => route.path,
  () => {
    showMenu.value = false
    showSearchSuggestion.value = false
    searchSuggestion.value = []
    search.value = null
    openSearch.value = false
    emit('searchOpenned', false)
  },
  showMenu,
  val => {
    transitionName.value = val === true ? 'spin' : 'spin-cross'
  }
)
</script>

<style lang="scss" scoped>
.site-search {
  padding: 5px 10px;
  background: linear-gradient(#a5a5a5, #a5a5a5),
    linear-gradient(#a5a5a5, #a5a5a5), linear-gradient(#a5a5a5, #a5a5a5);
  background-size:
    1px 20%,
    100% 1px,
    1px 20%;
  background-position:
    bottom left,
    bottom center,
    bottom right;
  background-repeat: no-repeat;

  border: none;
}
.site-search-white {
  padding: 5px 10px;
  background: linear-gradient(#a5a5a5, #a5a5a5),
    linear-gradient(#a5a5a5, #a5a5a5), linear-gradient(#a5a5a5, #a5a5a5);
  background-size:
    1px 20%,
    100% 1px,
    1px 20%;
  background-position:
    bottom left,
    bottom center,
    bottom right;
  background-repeat: no-repeat;

  border: none;
}

:deep(.search-icon) {
  color: #ff6700 !important;
  &:hover svg path {
    fill: #ff6700 !important;
  }
  svg path {
    fill: #353535 !important;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

:deep(.search-icon-white) {
  &:hover svg path {
    fill: #ff6700 !important;
  }
  svg path {
    fill: white !important;
  }
}

// .children-title {
//   &:hover {
//     :deep(.nuxt-icon) {
//       svg {
//         // color: #ff6700;
//         fill: #ff6700;
//       }
//     }
//   }
// }

.site-logo {
  aspect-ratio: 5 / 2;
}

.logos-invert {
  filter: brightness(0) invert(1);

  &:hover {
    filter: brightness(1) invert(0);
  }
}
.ssc {
  @media screen and (min-width: 768px) {
    width: calc(100% - 124px);
    animation: widerwidth 0.3s;
  }
}

@keyframes widerwidth {
  0% {
    width: 30%;
  }
}

:deep(.three-lines-icon),
:deep(.cross-circle-icon) {
  svg {
    width: 24px;
    height: 24px;

    path {
      fill: #ff6700;
    }
  }
}

:deep(.router-link-active) {
  color: #ff6700;
}
.contact {
  color: #fff;
}

.search-suggestion {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.spin-enter-active {
  transition: all 0.2s ease-in-out;
  .three-lines-icon {
    transition: all 0.2s ease-in-out;
  }
}

.spin-leave-active {
  transition: all 0.2s ease-in-out;
  .three-lines-icon {
    transition: all 0.2s ease-in-out;
  }
}

.spin-enter-from {
  opacity: 0;
  .three-lines-icon {
    opacity: 0;
    transform: rotate(270deg);
  }
}
.spin-leave-to {
  opacity: 0;
  .three-lines-icon {
    opacity: 0;
    transform: rotate(270deg);
  }
}

.spin-cross-enter-active {
  transition: all 0.3s ease-in-out;
  // transition-delay: 0.2s;
}

.spin-cross-leave-active {
  transition: all 0.3s ease-in-out;
  // transition-delay: 0.2s;
}

.spin-cross-enter-from {
  opacity: 0;
}
.spin-cross-leave-to {
  opacity: 0;
}

.slide-fadetop-enter-active {
  transition: all 0.3s ease-in-out;

  .mobile-nav--menu {
    transition-delay: 0.15s;
    transform: translateX(-100px);
    opacity: 0.1;
  }
}
.slide-fadetop-leave-active {
  transition-delay: 0.25s;
  transition: all 0.3s ease-in-out;

  .mobile-nav--menu {
    transform: translateX(-50px);
    opacity: 0.1;
  }
}

.slide-fadetop-enter-from,
.slide-fadetop-leave-to {
  transform: translateY(-80px);
  opacity: 0;
}
</style>
