export default {
  data: [
    {
      old_url: '/sg',
      new_url: '/'
    },
    {
      old_url: '/services',
      new_url: '/expertises'
    },
    {
      old_url: '/services/digital-it-advisory',
      new_url: '/expertises/digital-it-advisory'
    },
    {
      old_url: '/services/ux-market-research',
      new_url: '/expertises/ux-market-research'
    },
    {
      old_url: '/services/development-consultation',
      new_url: '/expertises/development-consultation'
    },
    {
      old_url: '/services/monitoring-analytics',
      new_url: '/expertises/monitoring-analytics'
    },
    {
      old_url: '/services/brand-development',
      new_url: '/expertises/brand-development'
    },
    {
      old_url: '/services/ux-ui-design',
      new_url: '/expertises/ux-ui-design'
    },
    {
      old_url: '/services/content-creation',
      new_url: '/expertises/content-creation'
    },
    {
      old_url: '/services/motion-videography',
      new_url: '/expertises/motion-videography'
    },
    {
      old_url: '/services/ios-android-development',
      new_url: '/expertises/ios-android-development'
    },
    {
      old_url: '/services/web-development',
      new_url: '/expertises/web-development'
    },
    {
      old_url: '/services/e-commerce-solutions',
      new_url: '/expertises/e-commerce-solutions'
    },
    {
      old_url: '/services/search-engine-optimization',
      new_url: '/expertises/search-engine-optimization'
    },
    {
      old_url: '/services/creative-campaign',
      new_url: '/expertises/creative-campaign'
    },
    {
      old_url: '/services/social-media-management',
      new_url: '/expertises/social-media-management'
    },
    {
      old_url: '/services/digital-advertising',
      new_url: '/expertises/digital-advertising'
    },
    {
      old_url: '/services/influencer-marketing',
      new_url: '/expertises/influencer-marketing'
    }
  ]
}
