import { useMainMenuStore } from '~/store/mainMenu'

export default async () => {
  const mainMenu = useMainMenuStore()

  const { data, error } = await useSuitmediaApiData(
    `/api/menus/1?nested&populate=*`
  )

  if (error.value) {
    throw createError({
      ...error.value,
      statusMessage: 'Unable to fetch menus'
    })
  }

  mainMenu.changeMenus(data.value.data.attributes.items.data)

  return data
}
