import { useMainMenuStore } from '~/store/mainMenu'

export default async () => {
  const mainMenu = useMainMenuStore()

  const { data, error } = await useSuitmediaApiData(`/api/expertice-categories`)

  if (error.value) {
    throw createError({
      ...error.value,
      statusMessage: 'Unable to Expertises Categories'
    })
  }
  mainMenu.changeExpertises(data.value.data)

  return data
}
