export const useMainMenuStore = defineStore('mainMenu', {
  state: () => ({
    menus: [],
    expertises: []
  }),
  actions: {
    changeMenus(newMenus) {
      this.menus = newMenus
    },
    changeExpertises(newExpertises) {
      this.expertises = newExpertises
    }
  }
})
