<template>
  <div
    class="absolute bg-white overflow-y-auto w-full flex flex-col lg:hidden mobile-nav max-h-full z-30"
  >
    <div
      class="container mx-auto pt-2 mobile-nav--menu ease-in-out duration-200 transition-all"
    >
      <Transition :name="transitionName">
        <div v-if="showGrandchildMenu">
          <div class="back-to mx-auto mt-5">
            <button
              class="flex gap-5 hover:text-primary-primary return w-max text-bw-600"
              @click="backToMainList"
            >
              <NuxtIcon name="return" filled class="self-center return-icon" />
              <span class="text-base">{{
                childmenuData.attributes.title
              }}</span>
            </button>
          </div>
          <ul class="w-full flex flex-col gap-5 my-5">
            <template v-if="isLoading">
              <li v-for="i in 3" :key="i">
                <UtilsSkeletonLoading class="w-4/5 h-8" />
              </li>
            </template>
            <template v-else>
              <template v-if="childmenuData.attributes.title === 'Expertises'">
                <li
                  v-for="(expertise, index) in mainMenu.expertises"
                  :key="index"
                >
                  <p class="text-bw-600 pb-2 border-b border-bw-200 mb-3">
                    {{ expertise.title }}
                  </p>
                  <ul class="flex flex-col gap-5 mb-2">
                    <li v-for="item in expertise.grandchild" :key="item.id">
                      <NuxtLink
                        :to="`${childmenuData.attributes.url}/${item.attributes.slug}`"
                        :title="item.attributes.title"
                        class="text-bw-900 hover:text-primary-primary"
                      >
                        {{ item.attributes.title }}
                      </NuxtLink>
                    </li>
                  </ul>
                </li>
              </template>
              <template v-else>
                <li
                  v-for="item in childmenuData.grandchild"
                  :key="item.id"
                  class="cursor-pointer text-bw-900 text-base"
                >
                  <NuxtLink
                    :to="
                      childmenuData.attributes.url === '/expertises'
                        ? `${childmenuData.attributes.url}/${item.attributes.slug}`
                        : `${childmenuData.attributes.url}/${item.attributes.slug}`
                    "
                    :title="item.attributes.title"
                    class="flex items-center gap-3 justify-between hover:text-primary-primary"
                  >
                    {{ item.attributes.title }}
                  </NuxtLink>
                </li>
              </template>
            </template>
          </ul>
        </div>

        <div v-else>
          <form
            id="searchForm"
            class="search-input w-full flex items-center border-b border-gray-600 relative site-search h-12"
            autocomplete="off"
            @submit.prevent="onSiteSearch"
          >
            <button aria-label="search-button" type="submit" class="mr-4">
              <NuxtIcon name="search" filled class="search-icon" />
            </button>
            <input
              id="siteSearch"
              v-model="search"
              type="text"
              name="site-search"
              class="w-full focus-visible:outline-none text-bw-800"
            />
            <button
              v-show="search"
              aria-label="close-search-button"
              class=""
              type="button"
              @click="search = null"
            >
              <NuxtIcon name="cross" filled />
            </button>
          </form>
          <ul class="font-medium flex flex-col gap-6 my-6">
            <li v-for="menu in menus" :key="menu.id">
              <NuxtLink
                v-if="menu.attributes.children.data.length === 0"
                :to="menu.attributes.url"
                :title="menu.attributes.title"
                class="hover:text-[#FF6700] text-base"
              >
                {{ menu.attributes.title }}
              </NuxtLink>
              <div v-else class="main-list">
                <div class="parent-link cursor-pointer">
                  <div class="flex gap-x-2">
                    <span class="self-center parent-link-title">{{
                      menu.attributes.title
                    }}</span>
                  </div>
                </div>
                <div class="w-full children-list">
                  <ul class="p-4 w-full flex flex-col gap-5">
                    <li
                      v-for="(child, index) in childmenuWithoutCase(
                        menu.attributes.children.data
                      )"
                      :key="child.id"
                      class="flex items-center gap-3 cursor-pointer hover:text-primary-primary text-bw-900"
                      :class="
                        $route.path.includes(
                          child.attributes.title.toLowerCase()
                        )
                          ? 'text-primary-primary active-state'
                          : 'text-bw-900'
                      "
                      @click="onShowGrandchildMenu(child, index)"
                    >
                      <NuxtIcon
                        :name="child?.attributes?.icon"
                        filled
                        class="works-icon"
                      />
                      {{ child.attributes.title }}
                      <NuxtIcon
                        name="chevron-down"
                        filled
                        class="self-center rotate-[270deg] ml-auto"
                      />
                      <!-- </NuxtLink> -->
                    </li>
                    <li
                      class="cursor-pointer hover:text-primary-primary text-bw-900"
                    >
                      <NuxtLink
                        :to="
                          caseStudiesMenu(menu.attributes.children.data)
                            .attributes.url
                        "
                        :title="
                          caseStudiesMenu(menu.attributes.children.data)
                            .attributes.title
                        "
                        class="flex items-center gap-3 justify-between"
                      >
                        <div class="flex items-center gap-3">
                          <NuxtIcon
                            :name="
                              caseStudiesMenu(menu.attributes.children.data)
                                .attributes.icon
                            "
                            filled
                            class="works-icon"
                          />
                          {{
                            caseStudiesMenu(menu.attributes.children.data)
                              .attributes.title
                          }}
                        </div>
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <NuxtLink
                to="/contact"
                title="Contact"
                class="hover:text-[#FF6700] text-base"
              >
                Contact
              </NuxtLink>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { useMainMenuStore } from '~/store/mainMenu'

const props = defineProps({
  menus: {
    type: Array,
    default: () => []
  }
})

const router = useRouter()
const mainMenu = useMainMenuStore()
const transitionName = ref('slide-left')

const search = ref(null)
const showGrandchildMenu = ref(false)

const emit = defineEmits(['closeMenu'])

const childmenuData = ref({
  grandchild: []
})
const expertises = ref(mainMenu.expertises)

const isLoading = ref(false)
const activeChildMenu = ref(-1)

const getWorkList = async path => {
  const query = {}
  if (path === 'expertices') {
    query['populate[expertice_category][fields][0]'] = 'title'
    query['populate[expertice_category][fields][1]'] = 'slug'
  }

  if (path === 'contents') {
    query['pagination[pageSize]'] = 2
    query.populate =
      'image,layout_component,layout_component.type,study_case_client.image'
    query['filters[$and][0][layout][$eq]'] = 'study-case'
    query['sort[0]'] = 'createdAt:desc'
  }

  if (path === 'industries' || path === 'products') {
    query['sort[0]'] = 'title:asc'
  }

  if (path === 'solutions') {
    query['sort[0]'] = 'order'
  }

  const { data, error, pending } = await useSuitmediaApiData(`/api/${path}`, {
    query
  })

  isLoading.value = pending.value
  childmenuData.value.grandchild = data.value.data

  if (path === 'expertices') {
    const newExpertises = expertises.value.map(expert => {
      return {
        id: expert.id,
        ...expert.attributes,
        grandchild: data.value.data.filter(
          item =>
            item.attributes.expertice_category?.data?.attributes?.title ===
            expert.attributes.title
        )
      }
    })

    mainMenu.changeExpertises(newExpertises)
  }

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: 'Data does not exist'
    })
  }
}

const workMenu = props.menus.find(menu => menu.attributes.title === 'Work')
childmenuData.value = workMenu.attributes.children.data[0]

const onSiteSearch = () => {
  router.push({ path: '/search', query: { keyword: search.value, page: 1 } })
  emit('closeMenu')
}

const onShowGrandchildMenu = async (childmenu, i) => {
  activeChildMenu.value = i
  showGrandchildMenu.value = true
  childmenuData.value = childmenu

  if (childmenu.grandchild) return

  isLoading.value = true
  await getWorkList(childmenu.attributes.api_path)
}

const backToMainList = () => {
  showGrandchildMenu.value = false
  activeChildMenu.value = -1
}

const childmenuWithoutCase = menu => {
  return menu.filter(item => item.attributes.title !== 'Case Studies')
}
const caseStudiesMenu = menu => {
  return menu.find(item => item.attributes.title === 'Case Studies')
}

watch(showGrandchildMenu, val => {
  transitionName.value = val === true ? 'slide-left' : 'slide-right'
})
</script>

<style lang="scss" scoped>
.mobile-nav {
  min-height: calc(100vh - 64px);
}

:deep(.works-icon) {
  svg {
    width: 24px;
    height: 24px;
  }
}
:deep(.active-state) {
  .works-icon {
    svg {
      path {
        fill: #ff6700 !important;
      }
    }
  }
}

.main-list {
  :deep(.icon-light) {
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
  :deep(.icon-dark) {
    svg {
      path {
        fill: #4b4b4b !important;
      }
    }
  }
  &:hover {
    .parent-link {
      &:hover {
        :deep(.chevron-down-icon) {
          transform: rotate(180deg);
          transition: transform 300ms;
          svg {
            path {
              fill: #ff6700 !important;
            }
          }
        }
      }
    }
    .children-list {
      display: flex;

      .active-child {
        :deep(.chevron-right-icon) {
          transform: rotate(270deg);
          transition: transform 300ms;
          svg {
            path {
              fill: #ff6700 !important;
            }
          }
        }
      }
      :deep(.active-svg) {
        transform: rotate(270deg);
        transition: transform 300ms;
        svg {
          path {
            fill: #ff6700 !important;
          }
        }
      }
    }
  }
}

:deep(.router-link-active) {
  .works-icon {
    svg {
      path {
        fill: #ff6700 !important;
      }
    }
  }
}

.site-search {
  padding: 5px 10px;
  background: linear-gradient(#a5a5a5, #a5a5a5),
    linear-gradient(#a5a5a5, #a5a5a5), linear-gradient(#a5a5a5, #a5a5a5);
  background-size:
    1px 20%,
    100% 1px,
    1px 20%;
  background-position:
    bottom left,
    bottom center,
    bottom right;
  background-repeat: no-repeat;

  border: none;
}

:deep(.return-icon) {
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #ff6700 !important;
    }
  }
}

.slide-left-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-left-enter-from {
  transform: translateX(-80px);
  opacity: 0;
}
.slide-right-enter-active {
  transition: all 0.3s ease-in-out;
}

.slide-right-enter-from {
  transform: translateX(80px);
  opacity: 0;
}
</style>
