import validate from "/var/www/html/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/var/www/html/middleware/maintenance.global.ts";
import redirection_45global from "/var/www/html/middleware/redirection.global.ts";
import manifest_45route_45rule from "/var/www/html/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  redirection_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-rules": () => import("/var/www/html/middleware/redirectRules.ts")
}