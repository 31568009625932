import redirectRules from './redirectRules'

export default defineNuxtRouteMiddleware(to => {
  for (const rule of redirectRules.data) {
    if (to.path === rule.old_url) {
      return navigateTo(rule.new_url, { redirectCode: 301 })
    } else if (rule.old_url.includes('*')) {
      const excludePageURL = rule.old_url.split('*').filter(str => !!str) || []

      let breakFlag = false

      excludePageURL.forEach(str => {
        if (
          !to.path.includes(str) ||
          to.path.includes('expertises') ||
          to.path.includes('solutions') ||
          to.path.includes('industries') ||
          to.path.includes('products') ||
          to.path.includes('work') ||
          to.path.includes('articles') ||
          to.path.includes('videos') ||
          to.path.includes('podcasts')
        )
          breakFlag = true
      })

      if (!breakFlag) {
        let dynamicWords: any[] = []
        dynamicWords.push(to.path)

        excludePageURL.forEach(str => {
          const word = dynamicWords.pop()
          const splittedWord = word?.split(str) || []
          dynamicWords = [...dynamicWords, ...splittedWord]
        })

        let newUrl = rule.new_url
        dynamicWords.forEach((str, i) => {
          newUrl = newUrl.replace(`{{${i}}}`, str)
        })

        return navigateTo(newUrl, { redirectCode: 301 })
      }
    }
  }
})
